<template>
    <div>
        <div 
            v-if="!uploaded" 
            class="container">
            <div class="dropzone">
                <input
                    ref="input"
                    type="file"
                    class="input"
                    @change="handleInputChange"
                >

                <div class="dropzone-image"/>
                <div class="dropzone-text">Drag photo here to upload</div>
            </div>

            <div class="divider">or</div>

            <div class="button-container">
                <button
                    class="button"
                    @click.stop.prevent="handleButtonClick"
                >Browse files...</button>
            </div>
        </div>

        <div 
            v-else 
            class="uploaded-container">
            <div class="uploaded">
                <div class="uploaded-image"/>

                <button
                    class="uploaded-remove button"
                    @click.stop.prevent="handleUploadedRemoveClick"
                >Remove</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadForm',

    data() {
        return {
            uploaded: false,
        }
    },

    methods: {
        handleButtonClick() {
            const { input } = this.$refs

            input.click()
        },

        handleInputChange(e) {
            const { value } = e.target

            if (value && value !== '') {
                if (this.handleUpload(e)) {
                    this.uploaded = true
                }
            }

            this.uploaded = false
        },

        handleUploadedRemoveClick() {
            this.uploaded = false
        },

        handleUpload(e) {
            const [file] = e.target.files

            if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                alert('Invalid format')

                return false
            }

            const reader = new FileReader

            reader.addEventListener('load', e => {
                const data = typeof e.target.result === 'object' ?
                    window.URL.createObjectURL(new Blob([e.target.result])) :
                    e.target.result

                this.$emit('upload', e, {
                    base64: e.target.result,
                    fileName: file.name,
                })

                this.uploaded = true
            })

            reader.readAsDataURL(file)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.uploaded-container {
    display: flex;
    flex-wrap: wrap;

    & > * {
        width: 100%;
    }
}

.dropzone, .divider {
    margin-bottom: 30px;
}

.dropzone {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    background-color: #e8e8e8;
    border: 1px solid #979797;
    border-radius: 3px;
    transition: background-color 300ms;

    &-image {
        @include background-image('', 'upload.png');

        width: 100%;
        height: 60px;
        margin-bottom: 30px;
        background-size: contain;
        background-position: center;
    }

    &-text {
        font-size: 38px;
        line-height: 38px;
        font-weight: 300;
    }
}

.input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
}

.button-container, .divider {
    text-align: center;
}

.divider {
    font-size: 30px;
    line-height: 30px;
}

.button {
    display: inline-block;
    padding: 10px 40px;
    border: 0;
    border-radius: 40px;
    background-color: $brand-color;
    color: #fff;
    transition: background-color 300ms;
    outline: none;

    &:hover {
        background-color: lighten($brand_color, 5%);
    }
}

.uploaded {
    align-self: center;
    width: 100%;
    text-align: center;

    &-image {
        @include background-image('', 'checkmark.png');
    
        height: 80px;
        margin-bottom: 10px;
        background-size: contain;
        background-position: center;
    }

    &-remove {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 12px;
        background-color: $error_red;

        &:hover {
            background-color: lighten($error_red, 10%);
        }
    }
}
</style>

