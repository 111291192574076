var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.uploaded
      ? _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "dropzone" }, [
            _c("input", {
              ref: "input",
              staticClass: "input",
              attrs: { type: "file" },
              on: { change: _vm.handleInputChange },
            }),
            _c("div", { staticClass: "dropzone-image" }),
            _c("div", { staticClass: "dropzone-text" }, [
              _vm._v("Drag photo here to upload"),
            ]),
          ]),
          _c("div", { staticClass: "divider" }, [_vm._v("or")]),
          _c("div", { staticClass: "button-container" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleButtonClick.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Browse files...")]
            ),
          ]),
        ])
      : _c("div", { staticClass: "uploaded-container" }, [
          _c("div", { staticClass: "uploaded" }, [
            _c("div", { staticClass: "uploaded-image" }),
            _c(
              "button",
              {
                staticClass: "uploaded-remove button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleUploadedRemoveClick.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Remove")]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }